import React from "react";
import { useNavigate } from "react-router-dom";
import image404 from "../assets/img/404.jpg";

const NotFound = () => {
  const navigate = useNavigate();
  const navBackHome = () => navigate('/');

  return (
    <div className="error_page">
      <main>
        <div
          className="hero bg-image"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + image404})`,
          }}
        >
          <div className="content">
            <h1 data-aos="fade-up" data-aos-duration="1200">
              404!
            </h1>
            <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
              The page you are looking for could not be found.
            </p>

              <div
                className="button"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
                onClick={navBackHome}
                >
                <a href="/">BACK TO HOME</a>
              </div>
            {/* End purchase_button */}
          </div>
        </div>
      {/* End .hero */}
      </main>
    </div>
  );
};

export default NotFound;
