import RideShareCardImg from "../../assets/img/portfolio/rideshareicon.jpg";
import CrazyCopsCardImg from "../../assets/img/portfolio/crazycopzicon.png";
import GlobTrottrCardImg from "../../assets/img/portfolio/globetrottricon.jpg";
import LoginCardImg from "../../assets/img/portfolio/loginicon_895x552.png";
import GitGainzCardImg from "../../assets/img/portfolio/gitgainzicon.png"

const PortfolioData = [
  {
    id: 1,
    type: "React Web App",
    name: "RideShare",
    image: RideShareCardImg,
    tag: ["React.js", "Javascript", "Ruby on Rails"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "RideShare",
        client: "Personal Project",
        language: "React.js, Redux, Javascript, HTML, JSX, CSS,Bootstrap, MaterialUI, AWS S3, Ruby on Rails",
        preview: "https://rideshare-frontend.netlify.app/",
        link: "https://rideshare-frontend.netlify.app/",
        github:{
          frontend: "https://github.com/MisterMur/RideShare-FrontEnd",
          backedn: "https://github.com/MisterMur/RideShare-backend"
        }
      },
    ],
  },
  {
    id: 2,
    type: "React Native Mobile App",
    name: "Git-Gainz",
    image: GitGainzCardImg,
    tag: ["React Native", "Ruby on Rails"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Git-Gainz",
        client: "Personal Project",
        language: " React Native, Redux, Javascript, Victory Native (charting), Ruby on Rails ",
        preview: "Expo Git-Gainz",
        link: "https://expo.io/@brian.murillo/git-gainz",
        githubFront: "https://github.com/MisterMur/git-gainz-front",
        githubBack: "https://github.com/MisterMur/git-gainz-backend"
      },
    ],
  },
  {
    id: 3,
    type: "Javascript Game",
    name: 'Crazy Copz',
    image: CrazyCopsCardImg,
    tag: ["Javascript"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Javascript Game",
        client: "Personal Project",
        language: " Javascript, Phaser.io, Ruby on Rails, AWS S3",
        preview: "http://brianjmurillo.com.s3-website.us-east-2.amazonaws.com/",
        link: "http://brianjmurillo.com.s3-website.us-east-2.amazonaws.com/",
      },
    ],
  },
  {
    id: 4,
    type: "Ruby on Rails WebApp",
    name: "Globe-Trottr",
    image: GlobTrottrCardImg,
    tag: [ "Ruby on Rails",],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Ruby Webapp",
        client: "Personal Project",
        language: " Ruby on Rails, HTML, CSS",
        preview: "GLOBE-TROTTR LINK HERE",
        link: "Globe-Trottr Link Here",
        github: "https://github.com/MisterMur/globetrottr"
      },
    ],
  },
  {
    id: 5,
    type: `React Angular Complex Microservice WebApp`,
    name: "Employee Login",
    image: LoginCardImg,
    tag: ["React.js","Angular","Java Spring"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Web Application",
        client: "Personal Project",
        language: "HTML, CSS, ReactJS,Angular, Docker, Java Spring, Microservices",
        preview: "Login Link Desscrip",
        link: "add microservicelogin app link here ",
        github: "https://github.com/MisterMur/employee-login"
      },
    ],
  },
];

export default PortfolioData;
