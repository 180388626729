import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components"
import PortfolioData from "./portfolioData";
import Modal from "./modal/Modal";

const Portfolio = () => {
  const [getModal, setGetModal] = useState(false);
  const [modalId, setModalId] = useState(1);
  const [projectNameVisible, setProjectNameVisible] = useState(true);

  const handleModal = (id) => {
    setGetModal(true);
    setModalId(id);
  };

  const handleHov = () =>{
    setProjectNameVisible(true);
  }
  const handleBlur = () => {
    setProjectNameVisible(true);
  }

  const currentDate = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };


  return (
    <>
      <div className="portfolio-main">
        <div
          className="work-in-progress"      
          data-aos="fade-up"
          data-aos-duration="1200">
            <h4> Projects might not be available<br/>I am currently migrating databases and redeploying to new hosting services as of:</h4>
           
            <h5 className="current-time"> {currentDate.toLocaleDateString('en-us', options)}</h5>
         
            <h4> Heroku got rid of their free tier :( </h4>
        </div>
        <Tabs>
          <TabList className="portfolio-tab-list" data-aos="fade-up">
            <Tab>ALL</Tab>
            <Tab>React.js</Tab>
            <Tab>React Native</Tab>
            <Tab>Javascript</Tab>
            <Tab>Ruby on Rails</Tab>
            <Tab>Java Spring</Tab>
          </TabList>

          <div className="container">
            <TabPanel>
              <div className="tab-container">
                {PortfolioData.map((item) => {
                  const { id, type, image, delayAnimation } = item;
                  return (
                    <div
                      key={id}
                      data-aos="fade-right"
                      data-aos-delay={delayAnimation}
                    >
                      <div
                        className="tab-content"
                        onClick={() => handleModal(id)}
                        onMouseEnter={()=> handleHov()}
                        onMouseLeave={()=>handleBlur()}
                      >
                        <ProjectName nameVisible={projectNameVisible} >{item.name}</ProjectName>
                        <img src={image} alt="portfolio project demo" />
                        <ProjectTitle>
                          <span className="content-title">{type}</span>
                        </ProjectTitle>
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="tab-container">
                {PortfolioData.filter((item) => item.tag.includes("React.js")).map(
                  (item) => {
                    const { id, type, image, delayAnimation } = item;
                    return (
                      <div
                        key={id}
                        data-aos="fade-right"
                        data-aos-delay={delayAnimation}
                      >
                        <div
                          className="tab-content"
                          onClick={() => handleModal(id)}
                          onMouseEnter={()=> handleHov()}
                          onMouseLeave={()=>handleBlur()}
                        >
                          <ProjectName nameVisible={projectNameVisible} >{item.name}</ProjectName>
                          <img src={image} alt="portfolio project demo" />
                          <h3>
                            <span className="content-title">{type}</span>
                          </h3>
                        </div>
                        {/* {getModal && <Modal props={modalId} />} */}
                      </div>
                    );
                  }
                )}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="tab-container">
                {PortfolioData.filter((item) => item.tag.includes("React Native")).map(
                  (item) => {
                    const { id, type, image, delayAnimation } = item;
                    return (
                      <div
                        key={id}
                        data-aos="fade-right"
                        data-aos-delay={delayAnimation}
                      >
                        <div
                          className="tab-content"
                          onClick={() => handleModal(id)}
                          onMouseEnter={()=> handleHov()}
                          onMouseLeave={()=>handleBlur()}
                        >
                          <ProjectName nameVisible={projectNameVisible} >{item.name}</ProjectName>
                          <img src={image} alt="portfolio project demo" />
                          <h3>
                            <span className="content-title">{type}</span>
                          </h3>
                        </div>
                        {/* {getModal && <Modal props={modalId} />} */}
                      </div>
                    );
                  }
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="tab-container">
                {PortfolioData.filter((item) => item.tag.includes("Javascript")).map(
                  (item) => {
                    const { id, type, image, delayAnimation } = item;
                    return (
                      <div
                        key={id}
                        data-aos="fade-right"
                        data-aos-delay={delayAnimation}
                      >
                        <div
                        className="tab-content"
                        onClick={() => handleModal(id)}
                        onMouseEnter={()=> handleHov()}
                        onMouseLeave={()=>handleBlur()}
                      >
                        <ProjectName nameVisible={projectNameVisible} >{item.name}</ProjectName>
                          <img src={image} alt="portfolio project demo" />
                          <h3>
                            <span className="content-title">{type}</span>
                          </h3>
                        </div>
                        {/* {getModal && <Modal props={modalId} />} */}
                      </div>
                    );
                  }
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="tab-container">
                {PortfolioData.filter((item) =>
                  item.tag.includes("Ruby on Rails")
                ).map((item) => {
                  const { id, type, image, delayAnimation } = item;
                  return (
                    <div
                      key={id}
                      data-aos="fade-right"
                      data-aos-delay={delayAnimation}
                    >
                      <div
                        className="tab-content"
                        onClick={() => handleModal(id)}
                        onMouseEnter={()=> handleHov()}
                        onMouseLeave={()=>handleBlur()}
                      >
                        <ProjectName nameVisible={projectNameVisible} >{item.name}</ProjectName>
                        <img src={image} alt="portfolio project demo" />
                        <h3>
                          <span className="content-title">{type}</span>
                        </h3>
                      </div>
                      {/* {getModal && <Modal props={modalId} />} */}
                    </div>
                  );
                })}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="tab-container">
                {PortfolioData.filter((item) =>
                  item.tag.includes("Java Spring")
                ).map((item) => {
                  const { id, type, image, delayAnimation } = item;
                  return (
                    <div
                      key={id}
                      data-aos="fade-right"
                      data-aos-delay={delayAnimation}
                    >
                      <div
                        className="tab-content"
                        onClick={() => handleModal(id)}
                        onMouseEnter={()=> handleHov()}
                        onMouseLeave={()=>handleBlur()}
                      >
                        <ProjectName nameVisible={projectNameVisible} >{item.name}</ProjectName>
                        <img src={image} alt="portfolio project demo" />
                        <h3>
                          <span className="content-title">{type}</span>
                        </h3>
                      </div>
                      {/* {getModal && <Modal props={modalId} />} */}
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          </div>
        </Tabs>
      </div>
      {getModal && <Modal modalId={modalId} setGetModal={setGetModal} />}{" "}
    </>
  );
};

export default Portfolio;

const ProjectName = styled.h4`
  position: absolute;
  top: 20px;
  left: 5rem;
  transform: translate(-50%, -50%);
  height: 28px;
  width: 150px;
  // opacity:0.4;
  transition:visibility 0.3s linear,opacity 0.3s linear;
  // transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: black;
  background-color: white;
  text-transform: uppercase;
  border-radius: 0.5rem;
  z-index:1;
  visibility: ${props=> props.nameVisible ?  'visible': 'hidden'};

`;

const ProjectTitle = styled.h3`
h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background-color: var(--main-primary-color);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0.5rem;
  z-index: 2;

  .content-title {
      position: relative;
      transform: translateY(-20px);
      transition: 0.5s;
  }
}

&:hover {
  h3 {
      opacity: 1;
      visibility: visible;

      .content-title {
          transform: translateY(0);
      }
  }
}
`;