import { useState } from "react";
import img1 from "../assets/img/blog/blog-post-1.jpg";
import img2 from "../assets/img/blog/blog-post-2.jpg";
import img3 from "../assets/img/blog/blog-post-3.jpg";
import img4 from "../assets/img/blog/blog-post-4.jpg";
import img5 from "../assets/img/blog/blog-post-5.jpg";
import img6 from "../assets/img/blog/blog-post-6.jpg";

const AllBlogData = () => {
  const blogsData = [
    {
      id: 1,
      img: img1,
      title: "Perl Regex Email Validator",
      commentor: "Brian Murillo",
      date: "21 April 2022",
      tag: `wordpress, business, economy, design`,
      description1:
        `What is Regex?`,
      description2:
        `Wikipedia defines a  regular expression, regex or regexp (sometimes called a rational expression) as a sequence of characters that define a search pattern.
        Simply put regex makes performing operations on strings, such as manipulating, searching, and validating, simple.`,
      description3: `How to use it?`,
      description4:`At first glance, a regular expression may come across as daunting, a bunch of foreign symbols even; However once you understand the syntax its quite easy to start integrating Regex into your code.

      Let’s start from the beginning. In Perl, a regular expression boundary uses the forward slashes / / as delimiters which designate the start and end of the expression.
      
      There are three regular expression operators in Perl, that are distinguished by preceding the delimiters in the following manner:
      
      Match Regular Expression – m//
      Substitute Regular Expression – s///
      Transliterate Regular Expression –tr///`,
      description5: `Matching with Regex`,
      description6: `In order to test if a given string matches a regular expression, we use the =~ operator, or the !~ operator to test if a regular expression is not matched. 
      For example:
      $exp= "ThisMatches"
      if($exp =~ m/ThisMatches/)
      {
            print $exp . ' the regular expression';
      }
      elsif($exp !~ m/ThisMatches/)
      {
          print 'this doesnt match the regular expression'

      }
      OUTPUT: 'ThisMatches the regular expression'
      Now that we covered the most basic Regex example lets jump into it.
      `,
      description7: `Building The email validator expression:`,
      description8: `First, let’s define what makes up an email. For our purposes, we’re going to simplify the valid email requirements.

        Specs:
        username: the field to the left of the ‘@’ symbol which can include any alphanumeric character and the ‘.’ or ‘_’ characters.
        Domain: the field to the right of the @ symbol which can include any alphanumeric character and the ‘.’ or ‘_’ characters.
        .com: for our purposes, let’s require all emails to end in ‘.com’
        
        Okay, We know our Specs how do we start? lets first validate the username.
        
        Sets and ranges allow us to spice things up in our Regular expressions making them more useful. you can include sets designated with brackets [ ] that will match anything included in the set. And Ranges will provide any character between two given characters as a parameter. (a-z will include all lowercase letters, A-Z will include all uppercase letters and 0-9 will include any number character)
        
        $username= "funky.munkey_10"
        if($username=~ m/[a-zA-Z0-9._/)
        {
              print $username . ' is a valid username for an email';
        }
        OUTPUT: 'funky.munkey_10 is a valid username for an email'
        So we validated the username portion of the email, which can include any alphanumeric combination and include ‘.’ whats next? The ‘@’ symbol.
        
        At this point, you’re probably wondering can you combine sets within regex? The answer: yes, very easily.
        
        To Combine sets simply use the ‘+’ operator.
        
        Let’s add that to our email validator and also include a set to validate the domain portion of the email which is very similar to the validation of the username. Additionally, we can just as easily tack on the +.com to our regex to validate the last portion.
        
        $email= "funky.munky_10@me.com"
        if($email=~ m/[a-zA-Z0-9.]+@[a-zA-Z0-9.]+.com/)
        {
              print $email. ' is a valid email';
        }
        OUTPUT: 'funky.munky_10@me.com is a valid email'`,
    },
    {
      id: 2,
      img: img2,
      title: "Top 10 Toolkits for Deep Learning in 2022",
      commentor: "Brian Murillo",
      date: "14 January 2022",
      tag: `wordpress, business, economy, design`,
      description1:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      description2:
        "If you’ve been waiting for an invitation, this calligraphy is it. Commissioned by Notebook hand-lettered design for a poster. Quote is Notebook Building 8 VP’s Regina Dugan—and mine. ",
      description3:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
      description4:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 3,
      img: img3,
      title: "Everything You Need to Know About Web Accessibility",
      commentor: "Brian Murillo",
      date: "9 January 2020",
      tag: `wordpress, business, economy, design`,
      description1:
        "Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      description2:
        "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
      description3:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
      description4:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 4,
      img: img4,
      title: "How to Inject Humor & Comedy Into Your Brand",
      commentor: "Brian Murillo",
      date: "15 March 2022",
      tag: `wordpress, business, economy, design`,
      description1:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Suspendisse interdum consectetur libero id faucibu nisl. Lacus vel facilisis volutpat est velit egestas",
      description2:
        "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
      description3:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
      description4:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 5,
      img: img5,
      title: "Women in Web Design: How To Achieve Success",
      commentor: "Brian Murillo",
      date: "9 January 2021",
      tag: `wordpress, business, economy, design`,
      description1:
        "Jorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      description2:
        "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
      description3:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
      description4:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 6,
      img: img6,
      title: "Evergreen versus topical content: An overview",
      commentor: "Brian Murillo",
      date: "9 January 2022",
      tag: `wordpress, business, economy, design`,
      description1:
        "Still ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      description2:
        "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
      description3:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
      description4:
        "Still ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];

  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  return {
    singleData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
  };
};

export default AllBlogData;
