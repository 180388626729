import React from "react";

const experienceContent = [
  {
    year: "   2022 - Present",
    position: " Software Engineer",
    compnayName: "ASAP Delivery / Waitr",
    details: [
      `❏ Spearheaded the engineering rebrand and reskin of Waitr to ASAP for the web ordering e-commerce platform`,
      `❏ Collaborated with cross-functional teams to turn Figma mockups and design requirements into production-level, intuitive
          UIs with the use of React.js, Bootstrap, and SCSS.`,
      `❏ Oversaw the complete release process, guaranteeing that each JIRA ticket successfully progressed into production`,
      `❏ Developed consumer-facing features across several repos working with React, Angular 2 with Typescript, and GoLang `,
      `❏ Created reusable components, custom React Hooks, and utility functions to bolster engineers’ workflow efficiency`
    ]
  },
  {
    year: "2020 - 2022",
    position: "Application Developer",
    compnayName: "IBM",
    details: [
      `❏ Collaborated on remote AGILE teams alongside developers and non-technical roles using JIRA and Trello`,
      `❏ Led an initiative to help meet WCAG 2.1 accessibility standards of at least 90% throughout the project`,
      `❏ Wrote meaningful unit-tests using Jest meeting code coverage metrics defined by SonarQube`,
      `❏ Consumed RESTful API data using React Hooks, and managed global state using Redux`,
      `❏ Used cloud services on AWS, IBM Cloud, and Azure, including EC2, ECR, S3, Route53, and IAM principles`,
      `❏ Contributed to complex code-bases with multiple environments, utilizing Ci/Cd tools like Jenkins, TravisCI and OpenShift`,
      `❏ Built backend RESTful API microservices with GoLang, gRPC, and Node.js on top of containerized docker images and K8s`,
      `❏ Utilized Git as a version control system, and participated in the peer review process`,
      `❏ Mentored apprentices and interns, having them shadow me on projects whilst helping them grow as developers`
  ]
  },
  {
    year: "2013 - 2019",
    position: "I.T. Support Specialist",
    compnayName: "Independent",
    details: [
      `❏ Designed a mobile responsive, company website, with HTML and CSS, hosted on local server with IIS`,
      `❏ Used PowerShell cmdlets to automate the process of creating AD users, defining group policy, and maintaining backups`,
      `❏ Configured Remote Desktop Services for clients to access resources externally, managing user connections in MS SQL`,
      `❏ Saved business ~$20,000 by removing GrandCrab 5.1 ransomware and implementing prevention against similar exploits`,
      `❏ Communicated directly with end-users to troubleshoot issues relating to office software and wrote documentation`
    ] 
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          {val.details.map((bullet,j) => (
            <p key={j} className="open-sans-font">{bullet}</p>
          ))}
        </li>
      ))}
    </ul>
  );
};

export default Experience;
